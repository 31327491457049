import React from 'react'

import SmsMessage from './SmsMessage'
import { SmsPreviewnPublish } from './SmsPreviewnPublish'
import { CampaignDetails, WhenToSend, WhomToSend } from '../components'
import { SMSTemplate } from './SMSTemplate'

const SmsCampaign = ({ active, form, senderDetails, setForm, hideSteps }) => {
  switch (active) {
    case 'Campaign Details':
      return (
        <CampaignDetails
          showContent
          campaignType="SMS"
          form={form}
          setForm={setForm}
        />
      )

    case 'Sender ID & Template':
      return <SMSTemplate form={form} setForm={setForm} />

    case 'Message':
      return (
        <SmsMessage
          form={form}
          setForm={setForm}
          senderDetails={senderDetails}
        />
      )

    case 'Whom to Send':
      return <WhomToSend form={form} setForm={setForm} uploadedFrom="sms" />

    case 'When to Send':
      return <WhenToSend form={form} setForm={setForm} />

    case 'Preview & Publish':
      return (
        <SmsPreviewnPublish
          form={form}
          setForm={setForm}
          hideSteps={hideSteps}
        />
      )

    default:
      return null
  }
}

export default SmsCampaign
