import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import swal from 'sweetalert'
import checkFeatureAlloted from '../../utils/checkFeatureAlloted'

import Dashboard from 'components/Content/Dashboard'
import UserAccess from 'components/Content/userAccess/UserAccess'
import ConsumerAnalytics from 'components/Content/consumerAnalytics/ConsumerAnalytics'
import ConsumerAnalyticsSegment from 'components/Content/consumerAnalytics/AnalyticsSegment'
import ConsumerAnalyticsCustomers from 'components/Content/consumerAnalytics/Customers'
import ConsumerAnalyticsExplore from 'components/Content/consumerAnalytics/Explore'
import ConsumerAnalyticsNumbers from 'components/Content/consumerAnalytics/ConsumerAnalyticsNumbers'
import ConsumerAnalyticsOld from 'components/Content/consumerAnalytics/ConsumerAnalytics_old'
import BillingAnalytics from 'components/Content/billingAnalytics/BillingAnalytics'
import Profile from 'components/Content/profile/Profile'
import FeedbackList from 'components/Content/feedback/FeedbackList.jsx'
import FeedbackCampaignList from 'components/Content/feedback/FeedbackCampaignList.jsx'
// import RechargeMain from 'components/Content/Recharge/RechargeMain'
import TransactionSuccess from 'components/Content/Recharge/TransactionSuccess'
import TransactionFail from 'components/Content/Recharge/TransactionFail'
// import BillUsage from 'components/Content/Recharge/UsageComponent'
// import BillInvoice from 'components/Content/Recharge/Bill_InvoiceComponent'
import TransactionPending from 'components/Content/Recharge/TransactionPending'
import AboutUs from 'components/Content/AboutUs'
import Legal from 'components/Content/Legal/Legal'
import Sidebar from './Sidebar'
import Advertisment from 'components/Content/advert/Advertisment'
// import ConsumerMain from 'components/Content/consumerEngagementNew/ConsumerMain'
import ManageStores from 'components/Content/manageStores/ManageStores'
import Bills from 'components/Content/bills/Main'
import SecurityView from 'components/Content/bills/securityDesign'
import {
  forKantiSweets,
  // CinepolisCompanyIds,
  analyticsEnabledVendors,
  mcDonaldsVendor,
} from '../conditionalComponent'
import { checkReadPermission, logoutUser } from 'redux/actions/authActions'
import { setBillsCashier } from 'redux/actions/bills'
import { setSideBarLink } from 'redux/actions/sidebarLinkActions'
import { SidebarHolder } from 'styledComponent/components/layout/sidebar'
import ConsumerProfiling from 'components/Content/consumerProfiling'
import Settings from 'components/Content/Settings'
import { UploadExcel } from 'components/Content/consumerProfiling/UploadExcel'
import { CRMForm } from 'components/Content/consumerProfiling/Customer'
import ProductAnalytics from 'components/Content/Products/ProductAnalytics'
import AddOrEditProduct from 'components/Content/Products/AddOrEditProduct'
import UploadProducts from 'components/Content/Products/UploadProducts'
import AdvancedMode from 'components/Content/Products/AdvancedMode'
import Reports from 'components/Content/reports'
import GenerateReport from 'components/Content/reports/generateReport'
import AutoEngagement from 'components/Content/autoEngagement'
import UsageAndInvoices from 'components/Content/usageAndInvoices/index.jsx'
import { AdBelowBill } from 'components/Content/autoEngagement/advert'
import Coupons from 'components/Content/autoEngagement/coupon'
import CreateCoupon from 'components/Content/autoEngagement/coupon/CreateCoupon'
import UploadCoupon from 'components/Content/autoEngagement/coupon/CouponUpload'
import { SellBelowBill } from 'components/Content/autoEngagement/sellBelowbill'
import { BannerInBill } from 'components/Content/autoEngagement/banner'
import SmsEngage from 'components/Content/autoEngagement/sms'
import EmailEngage from 'components/Content/autoEngagement/email'
import UploadedData from 'components/Content/autoEngagement/uploadedData'
import Survey from 'components/Content/autoEngagement/survey'
import SurveyEngage from 'components/Content/autoEngagement/survey/createSurvey'
import surveyResponses from 'components/Content/autoEngagement/survey/survey-responses'

import { routeList, userTypes } from './constants'
import { SurveyInBill } from 'components/Content/autoEngagement/surveyInBill'
import UserComplaints from 'components/Content/UserComplaints'
import ProductManagement from 'components/Content/Products/ProductManagement'
import { PopupOverBill } from 'components/Content/autoEngagement/popupOverBill'
import Journey from 'components/Content/journey'
import CreateJourney from 'components/Content/journey/createJourney'
import NewSettings from 'components/Content/NewSettings'
import PageNF from 'assets/images'
import Segment from 'components/Content/consumerAnalytics/AnalyticsSegment'
import { Billing } from 'components/Content/Billing'
import storeManagement from 'components/Content/storeManagement'
import {
  BrandForm,
  CompanyForm,
  StoreGroupForm,
  StoreForm,
} from 'components/Content/storeManagement/forms'
import { ManageKeys } from 'components/Content/storeManagement/components/ManageKeys'
import WhatsApp from 'components/Content/autoEngagement/WhatsApp'
// import { PopupOverBill } from 'components/Content/autoEngagement/popupOverBill'

const PrivateRoute = connect(
  (state) => ({ selectedVendor: state.vendorIds.selectedVendor }),
  { checkReadPermission }
)(({ component: Component, checkReadPermission: checkAuth, ...rest }) => {
  const { featuresAlloted } = rest.selectedVendor
  return (
    <Route
      {...rest}
      render={(props) => {
        const NotAuthorised = () => {
          swal({
            title: 'Unauthorised!',
            text: 'You are not allowed to access this page!',
            icon: 'warning',
            dangerMode: true,
          })
          return <Redirect to="/dashboard" />
        }
        return checkAuth(rest.componentId) &&
          checkFeatureAlloted(rest.permission, featuresAlloted) ? (
          <Component {...props} />
        ) : (
          NotAuthorised()
        )
      }}
    />
  )
})

class Wrapper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showWrapper: false,
    }
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((loc) => {
      const location = loc.pathname.split('/')[1]

      if (routeList[location] !== this.props.sidebarLink)
        this.props.setSideBarLink(routeList[location] || '')
    })
    if (this.props.history.location.search.indexOf('L-Token') > -1) {
      this.props.logoutUser(this.props.history.location.search)
    } else if (window.location.search.indexOf('token') > -1) {
      this.props.logoutUser(window.location.search, true)
    } else if (!this.props.auth.isAuthenticated) {
      localStorage.clear()
      this.props.history.push('/login')
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auth.isAuthenticated) {
      return { showWrapper: true }
    } else {
      return { showWrapper: false }
    }
  }

  excludeKantiRoutes = () => {
    if (!forKantiSweets.some((x) => this.props.vendorIds.includes(x))) {
      return (
        <React.Fragment>
          <PrivateRoute
            componentId="billingAnalysis"
            exact
            path="/billingAnalytics"
            component={BillingAnalytics}
          />
          <PrivateRoute
            componentId="bills"
            exact
            path="/bills"
            component={Bills}
          />
        </React.Fragment>
      )
    }
  }

  render() {
    if (this.state.showWrapper) {
      const { featuresAlloted } = this.props.selectedVendor
      return (
        <>
          <div className={this.props.toggleCheck} id="wrapper">
            <Sidebar />
            <SidebarHolder />
            <div id="page-content-wrapper">
              <div className="content-wrapper">
                <Switch>
                  <Route exact path="/" component={ManageStores} />
                  {![
                    userTypes.CASHIER,
                    userTypes.MANAGER,
                    userTypes.OPERATIONS,
                    userTypes.SECURITY,
                  ].includes(this.props.userType) &&
                    checkFeatureAlloted('dashboard', featuresAlloted) && (
                      <Route exact path="/dashboard" component={Dashboard} />
                    )}

                  {/* storeManagement */}
                  <PrivateRoute
                    exact
                    path="/storeManagement"
                    component={storeManagement}
                    permission="storeManagement"
                    componentId="storeManagement"
                  />
                  {/* Company */}
                  <PrivateRoute
                    exact
                    path="/storeManagement/createCompany"
                    component={CompanyForm}
                    permission="storeManagement"
                    componentId="storeManagement"
                  />
                  <PrivateRoute
                    exact
                    path="/storeManagement/viewCompany/:id"
                    component={CompanyForm}
                    permission="storeManagement"
                    componentId="storeManagement"
                  />
                  <PrivateRoute
                    exact
                    path="/storeManagement/editCompany/:id"
                    component={CompanyForm}
                    permission="storeManagement"
                    componentId="storeManagement"
                  />
                  {/* Brands */}
                  <PrivateRoute
                    exact
                    path="/storeManagement/createBrand"
                    component={BrandForm}
                    permission="storeManagement"
                    componentId="storeManagement"
                  />
                  <PrivateRoute
                    exact
                    path="/storeManagement/viewBrand/:id"
                    component={BrandForm}
                    permission="storeManagement"
                    componentId="storeManagement"
                  />
                  <PrivateRoute
                    exact
                    path="/storeManagement/editBrand/:id"
                    component={BrandForm}
                    permission="storeManagement"
                    componentId="storeManagement"
                  />
                  {/* Store Group */}
                  <PrivateRoute
                    exact
                    path="/storeManagement/createStoreGroup"
                    component={StoreGroupForm}
                    permission="storeManagement"
                    componentId="storeManagement"
                  />
                  <PrivateRoute
                    exact
                    path="/storeManagement/viewStoreGroup/:id"
                    component={StoreGroupForm}
                    permission="storeManagement"
                    componentId="storeManagement"
                  />
                  <PrivateRoute
                    exact
                    path="/storeManagement/editStoreGroup/:id"
                    component={StoreGroupForm}
                    permission="storeManagement"
                    componentId="storeManagement"
                  />
                  {/* Retail Store */}
                  <PrivateRoute
                    exact
                    path="/storeManagement/createRetailStore"
                    component={StoreForm}
                    permission="storeManagement"
                    componentId="storeManagement"
                  />
                  <PrivateRoute
                    exact
                    path="/storeManagement/viewRetailStore/:id"
                    component={StoreForm}
                    permission="storeManagement"
                    componentId="storeManagement"
                  />
                  <PrivateRoute
                    exact
                    path="/storeManagement/editRetailStore/:id"
                    component={StoreForm}
                    permission="storeManagement"
                    componentId="storeManagement"
                  />
                  {/* E-Commerce Store */}
                  <PrivateRoute
                    exact
                    path="/storeManagement/createEcomStore"
                    component={StoreForm}
                    permission="storeManagement"
                    componentId="storeManagement"
                  />
                  <PrivateRoute
                    exact
                    path="/storeManagement/viewEcomStore/:id"
                    component={StoreForm}
                    permission="storeManagement"
                    componentId="storeManagement"
                  />
                  <PrivateRoute
                    exact
                    path="/storeManagement/editEcomStore/:id"
                    component={StoreForm}
                    permission="storeManagement"
                    componentId="storeManagement"
                  />
                  <PrivateRoute
                    exact
                    path="/storeManagement/manageKeys/:id"
                    component={ManageKeys}
                    permission="storeManagement"
                    componentId="storeManagement"
                  />

                  <PrivateRoute
                    exact
                    path="/segment"
                    component={Segment}
                    permission="custSegmentation"
                    componentId="custSegmentation"
                  />
                  <PrivateRoute
                    exact
                    path="/settings"
                    permission="settings"
                    componentId="setting"
                    component={NewSettings}
                  />
                  {/* <Route
                    exact
                    path="/billing"
                    permission="serviceSubscribtion"
                    componentId="billing"
                    component={Billing}
                  /> */}
                  {/* AutoEngagement */}

                  <PrivateRoute
                    exact
                    auth="journey"
                    path="/journey"
                    permission="journey"
                    componentId="journey"
                    component={Journey}
                  />
                  <PrivateRoute
                    exact
                    path="/journey/:id"
                    permission="journey"
                    componentId="journey"
                    component={CreateJourney}
                  />
                  <PrivateRoute
                    exact
                    path="/journey/:id/view"
                    permission="journey"
                    componentId="journey"
                    component={CreateJourney}
                  />

                  <PrivateRoute
                    exact
                    componentId="autoEngage"
                    path="/auto-engage"
                    permission={[
                      'smsCampaign',
                      'emailCampaign',
                      'adCampaign',
                      'sellCampaign',
                      'bannerCampaign',
                      'surveyCampaign',
                      'popupCampaign',
                      'whatsAppCampaign',
                    ]}
                    component={AutoEngagement}
                  />
                  <PrivateRoute
                    componentId="autoEngage"
                    path="/auto-engage/bannerInBill/:id"
                    permission="bannerCampaign"
                    component={BannerInBill}
                  />
                  <PrivateRoute
                    componentId="autoEngage"
                    path="/auto-engage/adBelowBill/:id"
                    permission="adCampaign"
                    component={AdBelowBill}
                  />
                  <PrivateRoute
                    componentId="autoEngage"
                    path="/auto-engage/sellBelowBill/:id"
                    permission="sellCampaign"
                    component={SellBelowBill}
                  />
                  <PrivateRoute
                    componentId="autoEngage"
                    path="/auto-engage/surveyInBill/:id"
                    permission="surveyCampaign"
                    component={SurveyInBill}
                  />
                  <PrivateRoute
                    componentId="autoEngage"
                    path="/auto-engage/sms/:id"
                    permission="smsCampaign"
                    component={SmsEngage}
                  />
                  <PrivateRoute
                    componentId="autoEngage"
                    path="/auto-engage/email/:id"
                    permission="emailCampaign"
                    component={EmailEngage}
                  />
                  <PrivateRoute
                    componentId="autoEngage"
                    path="/auto-engage/popupOverBill/:id"
                    permission="popupCampaign"
                    component={PopupOverBill}
                  />
                  <PrivateRoute
                    componentId="autoEngage"
                    path="/auto-engage/whatsApp/:id"
                    permission="whatsAppCampaign"
                    component={WhatsApp}
                  />
                  <PrivateRoute
                    componentId="autoEngage"
                    path="/auto-engage/surveys/responses/:surveyId"
                    permission="surveys"
                    component={surveyResponses}
                  />
                  <PrivateRoute
                    componentId="autoEngage"
                    path="/auto-engage/surveys/:id"
                    permission="surveys"
                    component={SurveyEngage}
                  />
                  <PrivateRoute
                    exact
                    componentId="autoEngage"
                    path="/auto-engage/coupons/create"
                    permission="coupons"
                    component={CreateCoupon}
                  />
                  <PrivateRoute
                    exact
                    componentId="autoEngage"
                    path="/auto-engage/coupons/upload"
                    permission="coupons"
                    component={UploadCoupon}
                  />
                  <PrivateRoute
                    exact
                    componentId="autoEngage"
                    path="/auto-engage/coupons"
                    permission="coupons"
                    component={Coupons}
                  />
                  <PrivateRoute
                    exact
                    componentId="autoEngage"
                    path="/auto-engage/surveys"
                    permission="surveys"
                    component={Survey}
                  />
                  <PrivateRoute
                    exact
                    componentId="autoEngage"
                    path="/auto-engage/uploadedData"
                    component={UploadedData}
                    permission="mBank"
                  />
                  <PrivateRoute
                    exact
                    componentId="autoEngage"
                    path="/auto-engage/:id"
                    permission={[
                      'smsCampaign',
                      'emailCampaign',
                      'adCampaign',
                      'sellCampaign',
                      'bannerCampaign',
                      'surveyCampaign',
                      'popupCampaign',
                    ]}
                    component={AutoEngagement}
                  />

                  <PrivateRoute
                    exact
                    componentId="userAccess"
                    path="/userAccess"
                    permission="uAccess"
                    component={UserAccess}
                  />
                  {/* <PrivateRoute
                    componentId="customerAnalysis"
                    exact
                    path="/beta-consumer-analytics"
                    permission="cAnalytics"
                    component={ConsumerAnalyticsOld}
                  />
                  <PrivateRoute
                    componentId="profile"
                    exact
                    path="/profile"
                    permission="bProfile"
                    component={Profile}
                  /> */}
                  <PrivateRoute
                    componentId="feedback"
                    exact
                    path="/feedback"
                    permission="feedback"
                    component={FeedbackList}
                  />
                  <PrivateRoute
                    componentId="feedback"
                    exact
                    path="/feedback/responses"
                    permission="feedbackReplyPro"
                    component={FeedbackList}
                  />
                  <PrivateRoute
                    componentId="feedback"
                    exact
                    path="/feedback/campaigns"
                    permission="feedbackReplyPro"
                    component={FeedbackCampaignList}
                  />
                  {/* <PrivateRoute
                    componentId="recharge"
                    exact
                    path="/recharge"
                    component={RechargeMain}
                  />
                  <PrivateRoute
                    componentId="recharge"
                    exact
                    path="/usage"
                    component={BillUsage}
                  />
                  <PrivateRoute
                    componentId="recharge"
                    exact
                    path="/billInvoice"
                    component={BillInvoice}
                  /> */}
                  {/* Feature Temporarily Disabled */}
                  {/* <PrivateRoute
                  exact
                  componentId="feedback"
                  path="/customFeedback"
                  component={FeedbackSettingComponent}
                /> */}
                  {/* <PrivateRoute
                    componentId="loyalty"
                    exact
                    path="/loyaltySettings"
                    component={LoyaltySettings}
                  />
                  <PrivateRoute
                    componentId="loyalty"
                    exact
                    path="/redemption"
                    component={Redemption}
                  /> */}
                  <Route
                    exact
                    path="/trasaction-success"
                    component={TransactionSuccess}
                  />
                  <Route
                    exact
                    path="/trasaction-pending"
                    component={TransactionPending}
                  />
                  <Route
                    exact
                    path="/trasaction-failure"
                    component={TransactionFail}
                  />
                  <Route exact path="/about" component={AboutUs} />
                  <Route exact path="/legal" component={Legal} />
                  <Route
                    exact
                    path="/bill-complaints"
                    component={UserComplaints}
                    permission="custComplaints"
                  />
                  <PrivateRoute
                    componentId="productManagement"
                    exact
                    path="/product-management"
                    permission="products"
                    component={ProductManagement}
                  />
                  <PrivateRoute
                    componentId="productManagement"
                    exact
                    path="/product-management/products"
                    permission="products"
                    component={AddOrEditProduct}
                  />
                  <PrivateRoute
                    componentId="productManagement"
                    exact
                    path="/product-management/products/add/upload"
                    permission="products"
                    component={UploadProducts}
                  />
                  <PrivateRoute
                    componentId="productManagement"
                    exact
                    path="/product-management/products/:id"
                    permission="products"
                    component={AddOrEditProduct}
                  />

                  {/* {this.props.userEmail !== mcDonaldsVendor && ( */}
                  {/* <PrivateRoute
                    componentId="advert"
                    exact
                    path="/advertisement"
                    component={Advertisment}
                  /> */}
                  {/* )} */}
                  {/* <PrivateRoute
                    componentId="consumerSms"
                    exact
                    path="/consumerSms"
                    component={ConsumerMain}
                  /> */}
                  {/* <> */}
                  {/* {this.excludeKantiRoutes()}
                    {analyticsEnabledVendors.PA.includes(this.props.userEmail) ? ( */}
                  {/* <> */}
                  {/* <PrivateRoute
                    componentId="billingAnalysis"
                    exact
                    path="/billingAnalytics"
                    permission="bAnalytics"
                    component={BillingAnalytics}
                  /> */}
                  <PrivateRoute
                    componentId="bills"
                    exact
                    path="/bills"
                    permission="bills"
                    component={
                      this.props.userType === userTypes.SECURITY
                        ? SecurityView
                        : Bills
                    }
                  />
                  {/* <PrivateRoute
                    componentId="productAnalytics"
                    exact
                    path="/product-analytics"
                    permission="pAnalyticsPro"
                    component={ProductAnalytics}
                  /> */}
                  {/* <PrivateRoute
                    componentId="productAnalytics"
                    exact
                    path="/product-analytics/products"
                    permission="pAnalyticsPro"
                    component={AddOrEditProduct}
                  />
                  <PrivateRoute
                    componentId="productAnalytics"
                    exact
                    path="/product-analytics/products/add/upload"
                    permission="pAnalyticsPro"
                    component={UploadProducts}
                  />
                  <PrivateRoute
                    componentId="productAnalytics"
                    exact
                    path="/product-analytics/products/:id"
                    permission="pAnalyticsPro"
                    component={AddOrEditProduct}
                  /> */}
                  {/* <PrivateRoute
                    componentId="productAnalytics"
                    exact
                    path="/product-analytics/advanced"
                    permission="pAnalyticsPro"
                    component={AdvancedMode}
                  /> */}
                  {/* </> */}
                  {/* ) : null}
                    {analyticsEnabledVendors.CA.includes(this.props.userEmail) ? ( */}
                  {/* <> */}
                  {/* <PrivateRoute
                    exact
                    componentId="customerAnalysis"
                    path="/consumer-analytics"
                    permission="cAnalyticsPro"
                    component={ConsumerAnalytics}
                  /> */}
                  {/* <PrivateRoute
                    exact
                    componentId="customerAnalysis"
                    path="/consumer-analytics/segments"
                    permission="cAnalyticsPro"
                    component={ConsumerAnalyticsSegment}
                  /> */}
                  <PrivateRoute
                    exact
                    componentId="customerAnalysis"
                    path="/consumer-analytics/customers"
                    permission="cAnalyticsPro"
                    component={ConsumerAnalyticsCustomers}
                  />
                  {/* <PrivateRoute
                    exact
                    componentId="customerAnalysis"
                    path="/consumer-analytics/explore"
                    permission="cAnalyticsPro"
                    component={ConsumerAnalyticsExplore}
                  /> */}
                  {/* <PrivateRoute
                    componentId="customerAnalysis"
                    exact
                    path="/consumerAnalyticsContacts"
                    permission="cAnalyticsPro"
                    component={ConsumerAnalyticsNumbers}
                  /> */}
                  {/* </> */}
                  {/* ) : null}
                    {analyticsEnabledVendors.CRM.includes(
                      this.props.userEmail
                    ) ? ( */}
                  {/* <> */}
                  <PrivateRoute
                    componentId="crm"
                    exact
                    path="/consumer-profiling"
                    permission="crm"
                    component={ConsumerProfiling}
                  />
                  <Route
                    componentId="crmForm"
                    exact
                    path="/consumer-profiling/:id"
                    permission="crm"
                    component={CRMForm}
                  />
                  <Route
                    componentId="crmForm"
                    exact
                    path="/consumer-profiling/:id/edit"
                    permission="crm"
                    component={CRMForm}
                  />
                  <Route
                    componentId="uploadExcel"
                    exact
                    path="/consumer-profiling/add/upload"
                    permission="crm"
                    component={UploadExcel}
                  />
                  {/* </> */}
                  {/* ) : null} */}
                  <PrivateRoute
                    componentId="setting"
                    exact
                    path="/setting"
                    permission="settings"
                    component={Settings}
                  />
                  {/* {(analyticsEnabledVendors.PA.includes(
                      this.props.auth.user.email
                    ) ||
                      analyticsEnabledVendors.CA.includes(
                        this.props.auth.user.email
                      ) ||
                      analyticsEnabledVendors.CRM.includes(
                        this.props.auth.user.email
                      )) && ( */}
                  {/* <> */}
                  <PrivateRoute
                    componentId="reports"
                    exact
                    path="/reports"
                    permission="reports"
                    component={Reports}
                  />
                  <PrivateRoute
                    componentId="reports"
                    exact
                    path="/reports/generate-report"
                    permission="reports"
                    component={GenerateReport}
                  />
                  {/* <PrivateRoute */}
                  {/*   componentId="usageAndInvoices" */}
                  {/*   exact */}
                  {/*   path="/usage-and-invoices" */}
                  {/*   permission="companyUsage" */}
                  {/*   component={UsageAndInvoices} */}
                  {/* /> */}
                  <Route path="/" component={PageNF} />
                </Switch>
              </div>
            </div>
          </div>
        </>
      )
    }
    return null
  }
}

const mapStateToProps = (state) => {
  const vendorIds = state.vendorIds.vendorIds.map((x) => x.value)
  return {
    toggleCheck: state.toggle,
    auth: state.auth,
    layout: state.layout,
    userEmail: state.auth.user.email,
    sidebarLink: state.sidebarLink,
    stores: state.stores.stores,
    userType: state.auth.user.userType,
    vendorIds,
    selectedVendor: state.vendorIds.selectedVendor,
  }
}

export default connect(mapStateToProps, {
  checkReadPermission,
  logoutUser,
  setSideBarLink,
  setBillsCashier,
})(Wrapper)
