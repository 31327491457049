import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useDebouncedEffect, useSelectStore } from 'hooks'
import { spacing } from 'design-system'
import { FlexBox, Div } from 'styledComponent'
import { Text, Input, PopupContainer } from 'components/Common'
import { getSmsTemplates } from 'redux/actions/settingsActions'
import { GET_SEL_VALUE, SMS_ATTRIBUTES, SMS_PREVIEW } from '../constants'
import { $BlueBox, $SmsPreview, $SmsMessage } from '../AutoEngagement.styled'
import UploadModal from '../components/UploadModal'
import { UserAttributeModal } from '../components/UserAttributeModal'
import { SelectSurvey } from '../components/SelectSurvey'
import { SelectCoupon } from '../components/SelectCoupon'
import { CustomAttributeModal } from '../components/CustomAttributeModal'
import { CustomLinkTypeAttributeModal } from '../components/CustomLinkTypeAttributeModal'
import SMSTemplateImg from 'assets/icons/mobile-template.svg'
import { TraiDisclaimer } from '../components/TraiDisclaimer'
import config from 'config/config'

const SmsMessage = ({ senderDetails, form, setForm, fromJourney }) => {
  const dispatch = useDispatch()
  const { data: smsTemplatesList } = useSelectStore('settings.smsTemplatesList')

  const [isPopOpen, setIsPopOpen] = useState(false)
  // Need to do this because onclose changes setisopen to false
  const [isTraiPopOpen, setIsTraiPopOpen] = useState({
    isOpen: false,
    source: 'custom',
  })
  const [paramId, setParamId] = useState(-1)
  const [template, setTemplate] = useState(
    smsTemplatesList?.[0]?.template?.split('{#var#}') || []
  )
  const [templateParams, setTemplateParams] = useState(
    form?.content?.body?.templateParams?.length > 0
      ? form?.content?.body?.templateParams?.map((tp, i) => ({
          attribute:
            tp === '$custom[]'
              ? 'blank'
              : /\$(.*?)\[/.exec(tp)?.length > 0
              ? /\$(.*?)\[/.exec(tp)[1]
              : '',
          value: tp,
          display: form?.content?.body?.displayParams[i] || null,
        }))
      : new Array(template.length - 1)
  )
  const shortUrl = `${config.smsCampaignShortUrl}${
    form?.senderDetails?.name || senderDetails?.name
  }`

  const handleSelectParam = (i, attribute) => {
    const tempParams = [...templateParams]
    tempParams[i] = { attribute }
    setTemplateParams(tempParams)
    setParamId(i)
    setIsPopOpen(attribute)
  }

  useEffect(() => {
    const tempId = form.content?.body?.templateId
    if (tempId && tempId !== smsTemplatesList[0]?._id) {
      dispatch(getSmsTemplates({ _id: form.content?.body?.templateId }))
    }
    return () => setTemplateParams([])
  }, [])

  useDebouncedEffect(
    () => {
      const temple = smsTemplatesList?.[0]?.template?.split('{#var#}') || []
      if (template?.[0] !== temple?.[0]) {
        setTemplate(temple)
        setTemplateParams(new Array(temple.length - 1))
      }
    },
    [smsTemplatesList?.[0]?.template],
    100
  )

  useEffect(() => {
    setForm({
      ...form,
      content: {
        ...form?.content,
        body: {
          ...form?.content?.body,
          templateParams: templateParams.map((temp) => temp?.value),
          displayParams: templateParams.map((temp) => temp?.display),
        },
      },
    })
  }, [templateParams])

  const setEmbed = (value, field, display, nextPopup = false) => {
    setIsPopOpen(nextPopup)
    if (nextPopup === 'disclaimerPopup') {
      setIsTraiPopOpen({
        isOpen: true,
        source: field === 'asset' ? 'media' : field,
      })
    }
    const tempParams = [...templateParams]
    tempParams[paramId] = {
      ...tempParams[paramId],
      value: `$${field}[${value}]`,
      display,
    }
    setTemplateParams(tempParams)
  }

  useEffect(() => {
    if (isPopOpen === 'blank') {
      setEmbed('', 'custom', '{ }')
    }
  }, [isPopOpen])

  return (
    <Div>
      <FlexBox height="520px" gap={spacing.l} justify="space-between">
        <Div width="100%" pt={spacing.l}>
          <Text variant="h3" weight="bold" type="main" mb={spacing.l}>
            Message
          </Text>
          <$SmsMessage>
            {template?.map((sms, i) => (
              <span key={sms + i}>
                {sms}
                {i !== template.length - 1 && (
                  <Div display="inline-block" width="200px" key={sms + i}>
                    <Input
                      key={sms + i}
                      containerMargin="0"
                      variant="single-select"
                      placeholder="Select Attribute"
                      options={SMS_ATTRIBUTES(fromJourney)}
                      value={
                        GET_SEL_VALUE(
                          templateParams[i]?.attribute,
                          SMS_ATTRIBUTES(fromJourney)
                        ) || ''
                      }
                      onChange={(val) => handleSelectParam(i, val.value)}
                    />
                  </Div>
                )}
              </span>
            ))}
          </$SmsMessage>
        </Div>
        <Div width="400px">
          <Text variant="h3" weight="bold" type="main" mb={spacing.l}>
            Message Preview
          </Text>
          <$BlueBox height="450px">
            <$SmsPreview>
              <img src={SMSTemplateImg} />
              <big>{form?.senderDetails?.name}</big>
              <span>
                {SMS_PREVIEW(
                  smsTemplatesList[0]?.template,
                  form?.content?.body?.displayParams
                )}
              </span>
            </$SmsPreview>
          </$BlueBox>
        </Div>
      </FlexBox>

      <UploadModal
        showMedias={['image', 'gif', 'video', 'pdf']}
        isPopOpen={isPopOpen === 'asset'}
        setIsPopOpen={setIsPopOpen}
        setMedia={({ key }) =>
          setEmbed(
            key,
            'asset',
            `{ ${shortUrl}/<unique_number> }`,
            'disclaimerPopup'
          )
        }
        uploadedFrom="sms"
      />
      <UserAttributeModal
        isPopOpen={isPopOpen === 'attribute'}
        setIsPopOpen={setIsPopOpen}
        onAttributeChange={(value, field) =>
          setEmbed(value, field, `{ ${value} }`)
        }
      />
      <CustomAttributeModal
        isPopOpen={isPopOpen === 'custom'}
        setIsPopOpen={setIsPopOpen}
        onCustomChange={(value, field, nextPopup) => {
          if (nextPopup === 'disclaimerPopup')
            setEmbed(value, field, `{ ${shortUrl}/<unique_number> }`, nextPopup)
          else setEmbed(value, field, `{ ${value} }`, nextPopup)
        }}
        type="sms"
      />
      <TraiDisclaimer
        isPopOpen={isTraiPopOpen.isOpen}
        setIsPopOpen={setIsTraiPopOpen}
        shortUrl={shortUrl}
        source={isTraiPopOpen.source}
      />
      <CustomLinkTypeAttributeModal
        isPopOpen={isPopOpen === 'customLinkType'}
        setIsPopOpen={setIsPopOpen}
        onCustomLinkTypeChange={(value, field) =>
          setEmbed(
            value,
            field,
            `{ ${shortUrl}/<unique_number> }`,
            'disclaimerPopup'
          )
        }
      />
      <PopupContainer
        isPopOpen={['survey', 'coupon'].includes(isPopOpen)}
        setIsPopOpen={setIsPopOpen}
        heading={`Select ${isPopOpen}`}
      >
        {isPopOpen === 'coupon' ? (
          <SelectCoupon
            onCouponSelect={(coup) =>
              setEmbed(coup._id, 'coupon', `{ ${coup.prefix}-0000 }`)
            }
          />
        ) : (
          <SelectSurvey
            onSurveySelect={(sur) =>
              setEmbed(
                sur.surveyId,
                'survey',
                `{ ${shortUrl}/<unique_number> }`,
                'disclaimerPopup'
              )
            }
          />
        )}
      </PopupContainer>
    </Div>
  )
}

export default SmsMessage
