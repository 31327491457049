import React, { Component, useRef, createRef } from 'react'
import 'css/auth/auth.scss?v=3'
import styled from 'styled-components'
import { connect } from 'react-redux'
import {
  loginUser,
  verifyOTP,
  postLoginSuccess,
  verifyCustomOTP,
} from 'redux/actions/authActions'
import { getCookie } from 'utils/cookie'
import { spacing, color } from 'design-system'
import { ErrorMessage, FlexBox, CenterText } from 'styledComponent'
import { Process, Button, MyToast } from 'components/Common'
import Illustration from 'assets/icons/login-illustration.svg'
import BMLogo from 'assets/icons/bill-me-blue.svg'
import RightIllustration from 'assets/icons/login-right-illustration.svg'
import { toLowercase } from 'utils/stringManipulation'
import { userTypes } from '../Layout/constants'
import { getCompanies } from 'redux/actions/payments'
import { ssoConfig, captchaKey } from 'config/config'
import ReCAPTCHA from 'react-google-recaptcha'

const OTPTimer = 30

const ResendOtpText = styled(CenterText)`
  font-size: 12px;
  margin-top: ${spacing.l};
`

export const LoginContainer = ({ children }) => (
  <FlexBox expand height="100%" style={{ backgroundColor: '#fff' }}>
    <div style={{ flex: 0.6, height: '100%', position: 'relative' }}>
      <img
        src={BMLogo}
        alt="logo"
        style={{
          position: 'absolute',
          left: '30px',
          top: '30px',
          width: '350px',
        }}
      />
      <img
        src={Illustration}
        alt="logo"
        style={{
          position: 'absolute',
          left: '0px',
          bottom: '0px',
        }}
      />
    </div>
    <div style={{ flex: 0.4, position: 'relative' }}>
      <FlexBox
        direction="column"
        justify="center"
        align="center"
        expand
        height="100%"
      >
        {children}
      </FlexBox>

      <img
        src={RightIllustration}
        style={{
          position: 'absolute',
          right: '30px',
          bottom: '30px',
        }}
      />
    </div>
  </FlexBox>
)

export const LoginInput = styled.input`
  width: 100%;
  padding: ${spacing.s} ${spacing.l} ${spacing.s}
    ${(props) => props.paddingLeft || '0px'};
  border: none;
  border-bottom: 1px solid ${color.medium};
  outline: none;

  &:focus {
    border-color: ${color.primary.dark};
  }
`

const UsernamePage = ({
  onSubmit,
  handlePageOneInputOne,
  pageOneValueOne,
  errorOne,
  isSubmitting,
}) => {
  return (
    <>
      <div style={{ width: '100%' }}>
        <label htmlFor="username">Email / Phone No.</label>
        <br />
        <LoginInput
          id="username"
          onChange={handlePageOneInputOne}
          value={pageOneValueOne}
        />
        {errorOne && (
          <ErrorMessage error margin="0px">
            {errorOne}
          </ErrorMessage>
        )}
      </div>
      {/*<Button
        variant="success"
        isLoading={isSubmitting}
        marginTop={spacing.l}
        type="submit"
        onClick={onSubmit}
      >
        Login via OTP
      </Button>*/}
      <a href={ssoConfig.redirectUrl}>
        <Button
          width="100%"
          variant="primary"
          isLoading={isSubmitting}
          marginTop={spacing.m}
          type="button"
          onClick={() => {
            sessionStorage.setItem('ssoRedirected', true)
          }}
        >
          Login via Razorpay
        </Button>
      </a>
    </>
  )
}

const OTPPage = ({
  onSubmit,
  onPageTwoSecondarySubmit,
  isSubmitting,
  handlePageTwoInputTwo,
  pageTwoValueOne,
  pageTwoValueTwo,
  errorTwo,

  isVisible,
}) => {
  const [timer, setTimer] = React.useState(OTPTimer)
  const otpRef = useRef(null)

  React.useEffect(() => {
    if (isVisible) {
      otpRef.current.focus()
      timer > 0 && setTimeout(() => setTimer(timer - 1), 1000)
    } else if (!isVisible) setTimer(OTPTimer)
  }, [timer, isVisible])

  const handleOtpResend = (e) => {
    e.preventDefault()

    setTimer(OTPTimer)
    onPageTwoSecondarySubmit()
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <label htmlFor="otp">
          Enter the OTP sent to <strong>{pageTwoValueOne}</strong>
        </label>
        <LoginInput
          onChange={handlePageTwoInputTwo}
          value={pageTwoValueTwo}
          id="otp"
          ref={otpRef}
        />
        {errorTwo && (
          <ErrorMessage error margin="0px">
            {errorTwo}
          </ErrorMessage>
        )}
      </div>
      <Button
        variant="success"
        isLoading={isSubmitting}
        marginTop={spacing.l}
        type="submit"
        onClick={onSubmit}
      >
        Verify
      </Button>
      <div style={{ width: '100%', textAlign: 'center', height: '50px' }}>
        {timer > 0 ? (
          <ResendOtpText>Resend OTP in {timer} seconds</ResendOtpText>
        ) : (
          <Button
            variant="tertiary"
            marginTop={spacing.l}
            onClick={handleOtpResend}
          >
            Resend OTP
          </Button>
        )}
      </div>
    </>
  )
}

class Login extends Component {
  static contextType = MyToast

  constructor() {
    super()
    this.state = {
      username: '',
      otp: '',
      isUserExist: false,
      pageNumber: 1,
      isSendingOTP: false,
      isVerifyingOTP: false,
      otpLengthError: '',
      customOtp: false,
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.verifyOTP = this.verifyOTP.bind(this)
    this.sendOTP = this.sendOTP.bind(this)
    this.resendOTP = this.resendOTP.bind(this)
    this.handleOTPEnter = this.handleOTPEnter.bind(this)

    this.recaptchaRef = createRef()
  }

  componentDidMount() {
    const LToken = getCookie('L-Token')
    const ssoToken = localStorage.getItem('SSO-Token')
    if (LToken) {
      const decoded = atob(LToken.split('.')[1])
      this.setState({
        username: JSON.parse(decoded).email,
        otp: '123456',
      })
      setTimeout(
        () =>
          this.verifyOTP(undefined, {
            headers: {
              'L-Token': LToken,
            },
          }),
        0
      )
    }
    if (ssoToken && !LToken) {
      const decoded = atob(ssoToken.split('.')[1])
      this.setState({
        username: JSON.parse(decoded).email,
        otp: '123456',
      })
      setTimeout(
        () => this.verifyOTP(undefined, { headers: { 'Sso-Token': ssoToken } }),
        0
      )
    }
    localStorage.clear()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      getCookie(ssoConfig.cookieName) &&
      !localStorage.getItem('token') &&
      !sessionStorage.getItem('ssoRedirected') &&
      !getCookie('L-Token')
    ) {
      sessionStorage.setItem('ssoRedirected', true)
      localStorage.removeItem('SSO-Token')
      window.location.replace(ssoConfig.redirectUrl)
      return null
    }
    if (nextProps.auth.isAuthenticated) {
      const { phone, storeLogin, email, userId, ts } = nextProps.auth.user

      const [_, query] = window.location.href.split('?')

      if (
        query?.includes('nonce') ||
        query?.includes('response_type=id_token')
      ) {
        nextProps.history.push(
          `/sso?${query}&user=${email}&userId=${userId}&ts=${ts}`
        )
        return null
      }

      if (
        [
          userTypes.CASHIER,
          userTypes.MANAGER,
          userTypes.OPERATIONS,
          userTypes.SECURITY,
        ].includes(nextProps.auth.user.userType)
      ) {
        window.location.href = '/bills'
        // nextProps.history.push('/bills')
        // nextProps.history.push('/bills')
        return null
      }
      const shopifyLogin =
        nextProps?.auth?.user?.storeLogin ||
        nextProps?.registeredFrom === 'shopify'
      nextProps.getCompanies()
      if (phone) {
        if (shopifyLogin) nextProps.history.push('/bills')
        else nextProps.history.push('/dashboard')
      } else nextProps.history.push('/addContact')
    }

    return null
  }

  onChange(e) {
    const username = toLowercase(e.target.value)

    if (this.state.errorMessage) {
      this.setState({ errorMessage: '' })
    }

    this.setState({ username })
  }

  async sendOTP() {
    const user = this.state.username
    let data = ''
    const token = await this.recaptchaRef.current.executeAsync()
    this.recaptchaRef.current.reset()
    try {
      const [_, query] = window.location.href.split('?')

      data = await loginUser({
        user,
        customOtp:
          query?.includes('nonce') || query?.includes('response_type=id_token'),
        'g-recaptcha-response': token,
      })

      if (data.code) {
        this.context.toast(data.message, 'success', 'bottom-right')
        this.setState({
          isUserExist: true,
          ...(data?.data?.customOtp
            ? {
                customOtp: true,
              }
            : {}),
        })
        return true
      } else {
        this.context.toast(data.message, 'error', 'bottom-right')
        return false
      }
    } catch (e) {
      this.context.toast(
        'Some error occurred, please try again',
        'error',
        'bottom-right'
      )
    }
  }

  async onSubmit(e) {
    e && e.preventDefault()

    if (!this.state.username) {
      this.setState({ errorMessage: 'Email/Phone no. cannot be empty' })
      this.context.toast('Field is missing', 'error', 'bottom-right')
      return
    }

    this.setState({ isSendingOTP: true })

    const isUserExist = await this.sendOTP()
    this.setState({ isSendingOTP: false })

    if (isUserExist) this.setState({ pageNumber: 2 })
  }

  resendOTP() {
    this.sendOTP()
  }

  async verifyOTP(e, options = {}) {
    e && e.preventDefault()
    let data = {}
    const token = await this.recaptchaRef.current.executeAsync()
    this.recaptchaRef.current.reset()
    if (this.state.otp && this.state.otp.length === 6) {
      this.setState({ isVerifyingOTP: true })
      try {
        if (this.state.customOtp) {
          data = await verifyCustomOTP({
            user: this.state.username,
            otp: parseInt(this.state.otp),
            'g-recaptcha-response': token,
          })

          if (data.code) {
            //sso routing
            const [_, query] = window.location.href.split('?')

            if (
              query?.includes('nonce') ||
              query?.includes('response_type=id_token')
            ) {
              this.props.history.push(
                `/sso?${query}&user=${this.state.username}`
              )
            }
          } else {
            this.context.toast(data.message, 'error', 'bottom-right')
          }
        } else {
          data = await verifyOTP(
            {
              username: this.state.username,
              otp: parseInt(this.state.otp),
              'g-recaptcha-response': token,
            },
            options
          )
          if (data.code) {
            this.props.postLoginSuccess(data)
          } else {
            this.context.toast(data.message, 'error', 'bottom-right')
          }
        }

        this.setState({ isVerifyingOTP: false })
      } catch (e) {
        console.log('Error:', e)
        localStorage.removeItem('SSO-Token')
        this.context.toast(
          e?.response?.data?.message || 'Some Error occurred, please try again',
          'error',
          'bottom-right'
        )
      }
    } else {
      this.setState({ otpLengthError: 'OTP Should be 6 digit' })
      // this.context.toast("OTP Should be 6 digit.", "error");
    }
  }

  handleOTPEnter(e) {
    if (this.state.otpLengthError) this.setState({ otpLengthError: '' })

    if (e.target.value.length <= 6) this.setState({ otp: e.target.value })
  }

  render() {
    return (
      <LoginContainer>
        <CenterText as="h2" style={{ marginBottom: '1.6em' }}>
          Welcome to the <br /> Digital Revolution!
        </CenterText>
        <Process
          page={this.state.pageNumber}
          handlePageOneInputOne={this.onChange}
          pageOneValueOne={this.state.username}
          isPageOneSubmitting={this.state.isSendingOTP}
          pageOneErrorOne={this.state.errorMessage}
          pageTwoValueOne={this.state.username}
          pageTwoValueTwo={this.state.otp}
          handlePageTwoInputTwo={this.handleOTPEnter}
          pageTwoErrorTwo={this.state.otpLengthError}
          isPageTwoSubmitting={this.state.isVerifyingOTP}
          onPageOneSubmit={this.onSubmit}
          onPageTwoSubmit={this.verifyOTP}
          onPageTwoSecondarySubmit={this.resendOTP}
          PageOne={UsernamePage}
          PageTwo={OTPPage}
        />
        <ReCAPTCHA
          style={{ zIndex: 99999999999 }}
          sitekey={captchaKey}
          size="invisible"
          ref={this.recaptchaRef}
        />
      </LoginContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  isSubscribed:
    state.stores?.stores[Object.keys(state.stores?.stores)?.[0]]?.isSubscribed,
  onceOpened: state.bRed.onceOpened,
  registeredFrom: state.vendorIds.selectedVendor.registeredFrom,
  serviceSubscribtion:
    state.vendorIds.selectedVendor.featuresAlloted?.serviceSubscribtion,
})

export default connect(mapStateToProps, {
  loginUser,
  postLoginSuccess,
  getCompanies,
})(Login)
